import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { CuralateItem } from '~/util/curalate/Components/CuralateItem';
import { curalateImageSizes } from '~/util/curalate/curalate.constants';
import { ContentSlickSlider } from '~/components/content-slick-slider/ContentSlickSlider';
import { MagicHeaderTag } from '~/components/magic-header';
import { ViewerNextButton, ViewerPrevButton } from '~/util/media-viewer/Components/ViewerNavigationButtons';
import { isEngage, isOnServer } from '~/global/global.constants';
import { CuralateItemPlaceholder } from '~/util/curalate/Components/CuralateItemPlaceholder';
import { noop } from '~/util/noop';
import { CuralateContainer } from '~/util/curalate/Components/curalate-container/CuralateContainer';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { Link } from '~/components/Buttons/Components/Link';

import styles from '~/util/curalate/Components/curalate.module.scss';

const throttle = (callback, limit) => {
	// Initially, we're not waiting
	let waiting = false;
	// We return a throttled function
	return function () {
		// If we're not waiting
		if (!waiting) {
			// Execute users function
			callback.apply(this, arguments); // eslint-disable-line prefer-rest-params
			// Prevent future invocations
			waiting = true;
			// After a period of time
			setTimeout(function () {
				// And allow future invocations
				waiting = false;
			}, limit);
		}
	};
};

export const defaultCuralateBreakpointSettings = [
	{
		breakpoint: 1200,
		settings: {
			slidesToShow: 5,
			slidesToScroll: 5,
			arrows: true,
		},
	},
	{
		breakpoint: 1180,
		settings: {
			slidesToShow: 4,
			slidesToScroll: 4,
			arrows: true,
		},
	},
	{
		breakpoint: 900,
		settings: {
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: true,
		},
	},
	{
		breakpoint: 670,
		settings: {
			slidesToShow: 2,
			slidesToScroll: 2,
			arrows: true,
		},
	},
	{
		breakpoint: 500,
		settings: {
			slidesToShow: 1.25,
			slidesToScroll: 1,
			arrows: false,
		},
	},
];

export const Curalate = observer(({
	srcSetSizeKey = curalateImageSizes.LARGE_SQUARE.key,
	srcSizeKey = curalateImageSizes.MEDIUM_SQUARE.key,
	srcDimensions = curalateImageSizes.MEDIUM_SQUARE.dimensions,
	afterContent,
	beforeContent,
	className = '',
	curalateStore = {},
	headerLevel = '',
	headerClass = '',
	headingClass = 'md:tw-heading-3 tw-heading-4 tw-mb-2',
	heading = 'Great Customer Photos',
	subheading = 'Share your modern style #roomandboard',
	showAddPhoto = true,
	showHeading = true,
	seeMoreLink = '',
	skipRenderIfEmpty = false,
	slidesToShow = 5,
	slidesToScroll = 5,
	breakpointSettings = defaultCuralateBreakpointSettings,
}) => {
	const {
		openCuralateUploadModal,
		openCuralateDetailModal,
		model: {
			items = [],
		} = {},
	} = curalateStore;

	const curalateRef = useRef();
	const [placeholderCount, setPlaceholderCount] = useState(0);

	const handleItemClick = (item, itemRef) => {
		openCuralateDetailModal({ onCloseFocusElement: itemRef, item, heading });
	};

	const settings = {
		dots: false,
		slidesToShow,
		slidesToScroll,
		swipeToSlide: true,
		infinite: false,
		arrows: true,
		variableWidth: false,
		ref: curalateRef,
		nextArrow: <ViewerNextButton />,
		prevArrow: <ViewerPrevButton />,
		responsive: breakpointSettings,
	};

	const curalateItems = items.map((item, index) => {
		const {
			shouldLoadImage,
			hasLoadedImage,
		} = item;
		return (
			<CuralateItem
				deferImageLoad={!shouldLoadImage && !hasLoadedImage}
				clickHandler={handleItemClick}
				item={item}
				key={`curalate-item-${index}`}
				srcSetSizeKey={srcSetSizeKey}
				srcSizeKey={srcSizeKey}
				srcDimensions={srcDimensions}
			/>
		);
	});

	const placeholders = [];

	for (let x = 0; x < placeholderCount; x++) {
		placeholders.push(
			<CuralateItemPlaceholder key={`curalate-placeholder-${x}`} curalateStore={curalateStore} />
		);
	}

	const content = [...curalateItems, ...placeholders];

	const mediaQueryOnChange = throttle(() => {
		const viewportWidth = window.innerWidth;
		let visibleItemCount = 1;

		if (viewportWidth >= 1180) {
			visibleItemCount = 5;
		} else if (viewportWidth >= 900) {
			visibleItemCount = 4;
		} else if (viewportWidth >= 670) {
			visibleItemCount = 3;
		}

		setPlaceholderCount(visibleItemCount - items.length);
	}, 750);

	useEffect(() => {
		if (!isOnServer) {
			mediaQueryOnChange();
			if (items.length < 5) {
				window.addEventListener('resize', mediaQueryOnChange);
				return function () {
					window.removeEventListener('resize', mediaQueryOnChange);
				};
			}
		}
		return noop;
	});

	return (
		<CuralateContainer
			className={cn(className, {
				'tw-hidden': skipRenderIfEmpty && !items.length,
			})}
			curalateItems={curalateItems}
			trLinkEventCompName={heading}
		>
			<p className="tw-sr-only">The following is an image carousel of customer submitted images. Use the previous and next buttons to navigate.</p>
			<div data-qa="curalate-header" className={`${styles['curalate-header']} ${headerClass}`}>
				{beforeContent}
				{showHeading &&
					<>
						<MagicHeaderTag headerLevel={headerLevel} className={headingClass} props={{ 'data-qa': 'pg-name' }} >{heading}</MagicHeaderTag>
						{subheading &&
						<p className={styles['curalate-subheading']}>
							{subheading}
						</p>}
						{seeMoreLink &&
							<Link
								color={ButtonColor.Gray}
								href={seeMoreLink}
								variant={ButtonVariant.Hollow}
								className="tw-mb-3 tw-hidden md:tw-block"
							>
								See More Customer Photos
							</Link>
						}
						{showAddPhoto &&
							<button
								className={`ButtonAnchor ${styles['curalate-button']}`}
								data-tr-link-event-track={!isEngage}
								onClick={openCuralateUploadModal}
							>
								Add a photo
							</button>
						}
					</>
				}
			</div>
			{
				Boolean(curalateItems.length) && (
					<ContentSlickSlider
						content={content}
						settings={settings}
					/>
				)
			}
			{afterContent}
			{seeMoreLink &&
				<Link
					color={ButtonColor.Gray}
					href={seeMoreLink}
					variant={ButtonVariant.Hollow}
					className="tw-mt-2 tw-mb-6 md:tw-hidden"
				>
					See More Customer Photos
				</Link>
			}
		</CuralateContainer>
	);
});
